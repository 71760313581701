import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../utils/forms";
import { SINGLESIGNON } from "../utils/types";
import Button from "@material-ui/core/Button";
import { Typography, Divider, Box, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "../styles/formStyles";
import theme from "../styles/theme";
import { KiteTextInput } from "@kite/react-kite";
import { InfoIcon } from "./kiteComponents/IconComponents";
import { PageLoader } from "../themeComponents/Loader";
import apiService from "../utils/apiService";

const DHelpSection = styled("div")({
  marginTop: theme.spacing(4),
});

const ForgotUsername: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [screenState, setScreenState] = React.useState(1);
  const classes = useStyles();
  const history = useHistory();
  const [forgotUsernameError, setForgotUsernameError] = useState<any>(null);
  const { value: email, bind: bindEmail } = useInput("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await apiService.postData(
        process.env.REACT_APP_FORGOT_USERNAME as string,
        null,
        { email: email }
      );
      console.log("response", response);
      if (
        response.data.message === "Username has been sent to your email address"
      ) {
        setForgotUsernameError(null);
        setSuccessMessage(response.data.message);
        setScreenState(2);
      } else if (
        response.data.message === "No user found with this email address"
      ) {
        setForgotUsernameError(response.data.message);
      }
    } catch (error: any) {
      console.log("err", error);
      switch (error.data.message) {
        case "No user found with this email address":
          break;
        default:
          setForgotUsernameError("There was a problem, please try again later");
          break;
      }
    }
    setLoading(false);
  };

  const handleBackToLogin = () => history.push("/login");

  const disabledSendCode = !email;

  return (
    <div className={classes.root}>
      {screenState === 1 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            Forgot Username
          </Typography>
          <Typography className={classes.cardSubText} variant="subtitle1">
            {SINGLESIGNON.verificationUsernameWillBeSent}
          </Typography>

          <KiteTextInput
            className={classes.input}
            errorMessage=""
            id=""
            label="Email"
            name=""
            {...bindEmail}
          />
          {forgotUsernameError && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: theme.spacing(2) }}
              wrap="nowrap"
            >
              <Grid
                item
                style={{ width: "17px", marginRight: "5px", flexShrink: 0 }}
              >
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {forgotUsernameError}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={loading || disabledSendCode}
            className={classes.button + " " + classes.medWidthButton}
          >
            <Typography className={classes.buttonSubText}>
              {SINGLESIGNON.sendVerificationUsername}
            </Typography>
          </Button>
          <DHelpSection>
            <Divider className={classes.divider} />
            <Typography className={classes.helpSection}>
              <span className={classes.linkSpan}>
                {" "}
                {SINGLESIGNON.needHelp}{" "}
              </span>{" "}
              <Link to="/contact-us" className={classes.link}>
                {SINGLESIGNON.contactUs}
              </Link>
            </Typography>
          </DHelpSection>
        </form>
      )}
      {screenState === 2 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            Success
          </Typography>
          <Typography className={classes.cardSubText} variant="body1">
            {successMessage}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading || forgotUsernameError}
              className={classes.button + " " + classes.smallWidthButton}
              onClick={handleBackToLogin}
            >
              <Typography className={classes.buttonSubText}>
                {SINGLESIGNON.backToSignIn}
              </Typography>
            </Button>
          </Box>
          <DHelpSection>
            <Divider className={classes.divider} />
            <Typography className={classes.helpSection}>
              <span className={classes.linkSpan}>
                {" "}
                {SINGLESIGNON.needHelp}{" "}
              </span>{" "}
              <Link to="/contact-us" className={classes.link}>
                {SINGLESIGNON.contactUs}
              </Link>
            </Typography>
          </DHelpSection>
        </form>
      )}
      {loading && <PageLoader />}
    </div>
  );
};

export default ForgotUsername;
